<template>
  <el-date-picker style="width: 140px;" ref="mydatapicker" v-bind="$attrs" v-model="val" @change="change" size="small"
    :clearable="false" :picker-options="pickerOptions"></el-date-picker>
</template>
<script>
export default {

  data() {
    return {
      val: '',
      // 禁止选择超出当天的日期+禁止选择超出保质期外的日期
      pickerOptions: {
        disabledDate: this.disabledDate
      },
    }
  },
  props: {
    dateValue: {
      type: String,
      default: ''
    },
    expirationDay: {
      type: Number,
      default: 0
    }
  },
  methods: {
    change(value) {
      this.$emit('update:dateValue', value);
    },
    // 禁止选择日期范围
    disabledDate(time) {
      let limitDate =
        time.getTime() < new Date().getTime() - this.expirationDay * 86400000 ||
        time.getTime() >= Date.now();
      // 86400000:一天的毫秒
      return limitDate;
    },
  },

}
</script>
<style scoped lang='scss'>
:deep .el-input__inner {
  width: 150px !important;
}
</style>
