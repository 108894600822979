<!-- 发货详情 -->
<template>
  <div>
    <back />
    <div v-loading="loading">
      <div class="globle_border detail">
        <header>1、选择商品信息</header>
        <!-- 表格-->
        <div class="globle_table tableBox">
          <el-table height="390" :data="tableData" style="width: 100%">
            <el-table-column label="序列" show-overflow-tooltip min-width="60" align="center">
              <template slot-scope="scope">
                {{ scope.$index + 1 }}
                <!-- 这里可以获取到当前行的索引号 -->
              </template>
            </el-table-column>
            <!-- <el-table-column label="商品编号" prop="serialId" show-overflow-tooltip min-width="100" align="center">
            </el-table-column> -->
            <el-table-column prop="barCode" label="商品条形码" show-overflow-tooltip min-width="150" align="center">
            </el-table-column>
            <el-table-column label="商品分类" prop="categoryName" show-overflow-tooltip min-width="130" align="center">
            </el-table-column>
            <el-table-column label="商品品牌" prop="brand" show-overflow-tooltip min-width="100" align="center">
            </el-table-column>
            <el-table-column label="商品名称" prop="productName" show-overflow-tooltip min-width="130" align="center">
            </el-table-column>
            <el-table-column label="规格" prop="specs" show-overflow-tooltip min-width="100" align="center">
            </el-table-column>
            <el-table-column label="生产厂家" prop="factoryName" show-overflow-tooltip min-width="150" align="center">
            </el-table-column>
            <el-table-column prop="enterBox" label="入箱数" show-overflow-tooltip min-width="100" align="center">
            </el-table-column>
            <el-table-column label="采购价格(元)" prop="supplyPrice" show-overflow-tooltip min-width="110" align="center">
            </el-table-column>
            <el-table-column label="金额(元)" prop="totalAmount" show-overflow-tooltip min-width="100" align="center">
            </el-table-column>
            <el-table-column label="商品保质期(天)" prop="expirationDay" show-overflow-tooltip min-width="120" align="center">
            </el-table-column>
            <!-- <el-table-column label="采购数量" prop="purchaseCount"  show-overflow-tooltip min-width="100" align="center">
          </el-table-column>
          <el-table-column label="发货数量"  show-overflow-tooltip min-width="160" align="center">
            <template slot-scope="scope">
              <el-input-number v-model="scope.row.deliveryCount" size="small" :min="0"
                :max="Number(scope.row.purchaseCount)"></el-input-number>
            </template>
          </el-table-column> -->
            <el-table-column label="采购箱数" prop="productBox" show-overflow-tooltip min-width="100" align="center">
            </el-table-column>
            <el-table-column label="发货箱数" show-overflow-tooltip min-width="160" align="center" fixed="right">
              <template slot-scope="scope">
                <el-input-number v-model="scope.row.deliveryBox" size="small" :min="0"
                  :max="Number(scope.row.productBox)"></el-input-number>
              </template>
            </el-table-column>
            <el-table-column label="生产日期" show-overflow-tooltip min-width="160" align="center" fixed="right">
              <template slot-scope="scope">
                <MDataPicker :dateValue.sync="scope.row.productDate" type="date" placeholder="选择日期"
                  value-format="yyyy-MM-dd" :expirationDay="scope.row.expirationDay" />
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <!-- 收货人信息 -->
      <div class="globle_border detail">
        <header>2、输入送货信息</header>
        <!-- 后续更改为选择送货人姓名自动带出送货人手机号码，车牌号也是下拉选择 -->
        <el-form :model="ruleForm" :rules="rules" ref="formRef" label-width="150px" label-position="right">
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="送货人姓名：" prop="deliveryPerson">
                <el-select style="width: 100%" ref="pullingSelect" v-model="ruleForm.deliveryPerson"
                  placeholder="请选择送货人姓名">
                  <template #empty>
                    <el-table @row-click="clickData" :data="filterData" max-height="200px" highlight-selection-row>
                      <el-table-column label="姓名" align="center" prop="manName" width="100px" show-overflow-tooltip />
                      <el-table-column label="手机号码" align="center" prop="telNum" />
                    </el-table>
                  </template>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="送货人手机号码：" prop="deliveryPhone">
                <el-input v-model.trim="ruleForm.deliveryPhone" placeholder="手机号码" readonly></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="车牌号：">
                <el-select style="width: 100%" ref="carNumSelect" v-model="ruleForm.carNum" placeholder="请选择车牌号"
                  clearable>
                  <template #empty>
                    <el-table @row-click="clickCarNum" :data="carNumData" max-height="200px" highlight-selection-row>
                      <el-table-column label="车牌号" align="center" prop="carNum" show-overflow-tooltip />
                      <el-table-column label="车辆名称" align="center" prop="carName" />
                      <el-table-column label="载重量" align="center" prop="carLoad" />
                    </el-table>
                  </template>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <footer style="text-align: center">
        <el-button type="primary" @click="submitForm('formRef')">确认发货</el-button>
      </footer>
    </div>
  </div>
</template>

<script>
import MDataPicker from "/src/components/dataPicker/index"

export default {
  components: {
    MDataPicker,
  },
  data() {
    return {
      loading: false,
      // 表格信息
      tableData: [],
      formDate: {},
      // 收货人信息
      ruleForm: {
        deliveryPerson: "",
        deliveryPhone: "",
        carNum: "",
      },
      // 弹窗校验数据
      rules: {
        deliveryPerson: [{ required: true, message: "请输入送货人姓名", trigger: "blur" }],
        deliveryPhone: [
          { required: true, message: "请输入送货人手机号码", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/, // 中国大陆手机号码验证规则
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
      },
      // 下拉选择送货人信息
      filterData: [],
      // 下拉选择送货人车牌号
      carNumData: [],
    }
  },
  created() {
    this.getRequest() //列表
    this.getRequestPersons() //送货人信息
  },
  methods: {
    // 请求详情列表
    getRequest() {
      this.loading = true
      this.$axios
        .get(this.$api.sendOrderInfo, {
          params: {
            purchaseId: this.$route.query.purchaseId,
          },
        })
        .then(res => {
          const { code, result } = res.data
          this.loading = false
          if (code == 100) {
            this.formDate = result
            this.tableData = result.purchaseProductVoList.map(item => {
              return {
                ...item,
                productDate: "",
                // deliveryCount: item.purchaseCount//数量
                deliveryBox: item.productBox, //箱数
              }
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    // 提交发货
    getConfirmShipment() {
      // 判断是否填写日期，不填写则提示
      let hasEmptyProductDate = false
      this.tableData.forEach(item => {
        if (!item.productDate) {
          hasEmptyProductDate = true
          // this.$message.error(`请填写第${index + 1}行商品的生产日期!`);
        }
      })
      if (hasEmptyProductDate) {
        this.$message.error(`请检查商品的生产日期是否填写完毕!`)
        return
      } else {
        this.loading = true
        const data = {
          purchaseId: this.formDate.purchaseId, //采购单id
          purchaseNum: this.formDate.purchaseNum, //采购单编号
          shopId: this.formDate.shopId, //商城id
          shopName: this.formDate.shopName, //商超名称
          supplierId: this.formDate.supplierId, //供应商id
          supplierName: this.formDate.supplierName, //供应商Name
          deliveryPerson: this.ruleForm.deliveryPerson, //送货人
          deliveryPhone: this.ruleForm.deliveryPhone, //送货人电话
          carNum: this.ruleForm.carNum || null, //送货人车牌
          purchaseProductDtoList: this.tableData, //数据
        }
        this.$axios
          .post(this.$api.sendOrderConfirmShipment, data)
          .then(res => {
            const { code } = res.data
            this.loading = false
            if (code == 100) {
              this.$message({
                message: "恭喜你，发货成功!",
                type: "success",
              })
              this.$router.go(-1)
              // this.resetForm();
            }
          })
          .catch(err => {
            this.loading = false
            console.log(err)
          })
      }
    },
    // 送货人+车牌号信息请求
    getRequestPersons() {
      // 送货人
      this.$axios
        .get(this.$api.sendDeliveryManList)
        .then(res => {
          const { code, result } = res.data
          if (code == 100) {
            this.filterData = result.list
          }
        })
        .catch(err => {
          console.log(err)
        })
      // 送货车辆
      this.$axios
        .get(this.$api.sendDeliveryCarList)
        .then(res => {
          const { code, result } = res.data
          if (code == 100) {
            this.carNumData = result.list
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    // 送货信息验证
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          // 校验通过
          this.getConfirmShipment()
        } else {
          this.$message.error("请填写完整必填项内容")
          return false
        }
      })
    },
    // 重置表单
    resetForm() {
      this.ruleForm = {} //清空对象
      this.$refs.formRef.resetFields()
    },

    // 下拉选择送货人
    clickData(row) {
      this.ruleForm.deliveryPerson = row.manName
      this.ruleForm.deliveryPhone = row.telNum
      this.$refs.pullingSelect.blur() // 关闭选择器
    },
    // 下拉选择车牌号
    clickCarNum(row) {
      this.ruleForm.carNum = row.carNum
      this.$refs.carNumSelect.blur() // 关闭选择器
    },
  },
}
</script>

<style scoped lang="scss">
.detail {
  border-radius: 6px;
  box-sizing: border-box;

  header {
    height: 45px;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    color: #1a1a1a;
  }
}
</style>
